var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{staticClass:"is-fullwidth"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(_vm.$t('title'))}})])]),(_vm.advanced)?_c('tbody',{staticClass:"tabular-nums"},[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('medium'))}}),_c('td',{domProps:{"textContent":_vm._s(_vm.$t(("request_medium." + (_vm.advanced.medium))))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('referrer'))}}),(_vm.advanced.referrer)?_c('td',[(_vm.advanced.referrer.startsWith('https://'))?_c('I18nRouterLink',{attrs:{"to":{
            name: 'external-link',
            query: {
              url: _vm.advanced.referrer,
            },
          }},domProps:{"textContent":_vm._s(_vm.advanced.referrer)}}):_c('span',{staticClass:"font-mono",domProps:{"textContent":_vm._s(_vm.advanced.referrer)}})],1):_c('td',{domProps:{"textContent":_vm._s(_vm.$t('no_referrer'))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('budget'))}}),(_vm.advanced.budget_amount)?_c('td',{domProps:{"textContent":_vm._s(_vm.$n(_vm.advanced.budget_amount / 100, _vm.commission.currency))}}):_c('td',{domProps:{"textContent":_vm._s(_vm.$t('no_budget'))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('deadline'))}}),(_vm.advanced.deadline_at)?_c('td',{class:{
          'has-text-danger': _vm.isPastDeadline
            && !['complete', 'finished'].includes(_vm.commission.status),
          'has-text-primary': _vm.isPastDeadline
            && ['complete', 'finished'].includes(_vm.commission.status),
        }},[_c('span',{domProps:{"textContent":_vm._s(_vm.$d(new Date(_vm.advanced.deadline_at * 1000), 'DATE_TIME'))}}),_vm._v(" ("),_c('span',{domProps:{"textContent":_vm._s(_vm.$dd(new Date(_vm.advanced.deadline_at * 1000), new Date(), true))}}),_vm._v(") ")]):_c('td',{domProps:{"textContent":_vm._s(_vm.$t('no_deadline'))}})])]):_c('tbody',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('medium'))}}),_c('td',{attrs:{"rowspan":"4"},domProps:{"textContent":_vm._s(_vm.$t('unlock'))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('referrer'))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('budget'))}})]),_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('deadline'))}})]),_c('tr',[_c('td',{attrs:{"align":"center","colspan":"2"}},[_c('RouterLink',{staticClass:"font-semibold",attrs:{"to":{ name: 'dashboard.pricing' }},domProps:{"textContent":_vm._s(_vm.$t('upgrade'))}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }