






























































































































/* eslint-disable camelcase */
import { ApiResource } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    commission: {
      type: Object as () => ApiResource.Commission,
      required: true,
    },
  },
  computed: {
    advanced(): ApiResource.Commission['advanced_details'] {
      return this.commission.advanced_details;
    },
    isPastDeadline(): boolean {
      const deadlineAt = this.advanced?.deadline_at;
      if (!deadlineAt) return false;

      return new Date(deadlineAt * 1000) < new Date();
    },
  },
});
